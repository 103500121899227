import React from "react"

import { PageProps } from "gatsby"
import Layout from "../../components/layout"
import { ProjectCardComponent } from "../../components/projectCard"

const posts = [
    {
        title: 'Frontend Development',
        subtitle: 'Example of tradeoffs and team work',
        link: '/blog/live-session'
    }
]


export default function(props: PageProps) {
    console.log(posts)
    return (
        <Layout title="Blog">
            <div>
                {posts.map((post) =>
                    <ProjectCardComponent
                        key={post.title}
                        title={post.title}
                        subtitle={post.subtitle}
                        link={post.link}
                    />
                )}
            </div>
        </Layout>
    )
}