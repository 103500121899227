import React from "react"

interface projectInterface {
    title: string,
    subtitle: string,
    link: string
}

const ProjectCardComponent: React.FC<projectInterface> = ({ title, subtitle, link}) => (
    <a className="lg:w-2/6 md:w-1/2 mt-5 w-full" href={link}>
        <div className="group h-full card mx-2 bg-gradient-to-r hover:from-green-400 hover:to-blue-500 hover:text-white hover:subtitle-color-white">
            <div className="card-content">
                <h3>{title}</h3>
                <p className="subtitle group-hover:text-white">{subtitle}</p>
            </div>
        </div>
    </a>
)

export {
    ProjectCardComponent,
    projectInterface
}
